export const API_BASE = process.env.REACT_APP_API_ROOT;
export const ROOT = process.env.REACT_APP_ROOT;
export const APP_SOCKET = process.env.REACT_APP_SOCKET;
export const API_URL = API_BASE ;
export const MEDIA_BASE = API_BASE + '/static/';
export const APP_URL = 'https://staging.whaot.com';
export const PAYU = {
	URL: process.env.REACT_APP_PAYU_URL,
	MERCHANT_ID: process.env.REACT_APP_PAYU_MERCHANT_ID,
	MERCHANT_KEY: process.env.REACT_APP_PAYU_MERCHANT_KEY
};

export const SOCIALFBID = '1291112801628411';
export const SOCIALGOOGLEID = '312289298791-kru2vmpjnc0cknnmmehp4oga1h502jh9.apps.googleusercontent.com';
export const GOOGLE_CLIENT_ID = '311249512401-j3a25v82omoiu7qor8u6rt0jqg0ujcvj.apps.googleusercontent.com';
export const APPLE_CLIENT_ID = 'com.whaot.staging';
export const APPLE_REDIRECT_URL = 'https://api-staging.whaot.com/auth/apple/callback';

// App links
export const PLAYSTORELINK = 'https://play.google.com/store/apps/details?id=com.whaot';
export const APPSTORELINK = '#';

export const EXPERIENCES = {
	ZERO_TO_TWO: '0 - 2 years',
	TWO_TO_FIVE: '2 - 5 years',
	FIVE_TO_TEN: '5 - 10 years',
	TEN_TO_FIFTEEN: '10 - 15 years',
	FIFTEEN_TO_TWENTY_FIVE: '15 - 25 years',
	MORE_THAN_TWENTY_FIVE: 'More than 25 years',
};

export const EXPERTISE_STATUS = {
	VERIFIED: "verified",
	PENDING: "pending",
	REJECTED: "rejected"
};

export const INSTANT_CLASS_DURATION = {
	'0':'--Select--',
	'1800': '30 Min',
	'2700': '45 Min',
	'3600': '60 Min',
	'5400': '90 Min'
};

export const RATING_LABEL = [
	'',
	'Very Bad',
	'Poor',
	'Average',
	'I like it!',
	'Execelent'
];

export const USER_RATING_LABEL = [
	'',
	'Very Bad',
	'Poor',
	'Average',
	'Good',
	'Awesome!'
];

export const OCCUPATION_LIST = [
	{ label: 'Entrepreneur', value: 'Entrepreneur'},
	{ label: 'Consultant', value: 'Consultant'},
	{ label: 'Full-Time Employee', value: 'Full-Time Employee'},
	{ label: 'Freelancer', value: 'Freelancer'},
	{ label: 'Self-Employed', value: 'Self-Employed'},
	{ label: 'College Student', value: 'College Student'},
	{ label: 'Work-From-Home Parent', value: 'Work-From-Home Parent'},
	{ label: 'Homemaker', value: 'Homemaker'},
	{ label: 'Retired', value: 'Retired'},
	{ label: 'School Student', value: 'School Student'},
	{ label: 'Unemployed', value: 'Unemployed'},
];

export const AI_CLASS_ID = '660fad1f1a0e6a0011db72e8';

export const VEDIC_CLASS_ID = '6666ba6124895b0011b3b2ad';
export const ABACUS1_CLASS_ID = '6666c86624895b0011b3b2b8';
export const ABACUS2_CLASS_ID = '6666c9b524895b0011b3b2ba';

export const TNPSC_CLASS_ID = '6668273124895b0011b3b379';
export const FULL_STACK_CLASS_ID = '66701bd8db91840011de144c';
export const HACKING_CLASS_ID = '6667260f24895b0011b3b2ce';
export const CHESS_CLASS_ID = '66680e5db1298a00117fb676';
export const JEE_CLASS_ID = '66684c5824895b0011b3b38b';
export const IELTS_CLASS_ID = '6671863a230c200011848b24';

export const CLEAN_CODE = '66600ff81e04240010662a0b';

export const JITSI_MEET_URL = "jitsi-dev.whaot.com";

export const STRIPE = 'pk_test_51NM3q4SIHQ2eI7Ni8GSOa7vLa3Rn0wx4QHrxyBHlGoKyJGtCpXzNSr7F8qwCqLFBF9jCNna2C9IPZtxJCXtGt8Eb00xloXiXna';

export const RAZORPAY = "rzp_test_HZVGvRzMAmxJ0A";
